//
//
//
//
//
//
//
//
//
//

export default {
    name:'ShareMedia',
    computed: {
        shareMedia () {
            return [
                {
                    value:`https://www.facebook.com/sharer/sharer.php?u=https://www.ilajak.com${this.$route.fullPath}`,
                    title:'facebook',
                },
                {
                    value:`https://twitter.com/share?url=https://www.ilajak.com${this.$route.fullPath}`,
                    title:'twitter',
                },
                {
                    value:`https://api.whatsapp.com/send?text=https://www.ilajak.com${this.$route.fullPath}`,
                    title:'whatsapp',
                },
                {
                    value:`https://www.instagram.com/?url=https://www.ilajak.com${this.$route.fullPath}`,
                    title:'instagram',
                },
            ]
        }
    },
}
